<template>
    <div v-if="showSPBanner" class="sp-16-banner">
        <div class="text-right">
            <v-btn
                icon
                small
                @click="handleClickBanner"
            >
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </div>
        <div v-html="bannerText"/>

        <v-dialog
            v-model="showSPDialog"
            max-width="600"
            persistent
        >
            <v-card class="pa-8">
                <div v-html="bannerText"/>
                <v-btn
                    class="mt-8"
                    color="primary"
                    block
                    @click="handledClickDialog"
                >
                    Rozumím
                </v-btn>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    import { getUserRole } from '@/utils/localStorageUtils';

    export default {
        data() {
            return {
                showSPBanner: false,
                userRole: null,
                showSPDialog: false
            };
        },

        computed: {
            bannerText() {
                if (this.userRole === 'doctor') {
                    return `
                <p>Od 1. 1. 2025 dochází ke změně ve Stavovském předpisu č.16, který mění podmínky pro získání diplomu celoživotního vzdělávání.</p>
                <div class="font-weight-bold mb-2" style='text-decoration: underline'>Zde je výpis nejdůležitějších změn pro lékaře:</div>
                <ul class="mb-4">
                    <li>
                        Za <strong>stáže</strong> lze uznat pro získání diplomu celoživotního vzdělávání nejvíce <strong>75 kreditů</strong>.
                    </li>
                    <li>
                        Za <strong>e-learningové kurzy</strong> lze uznat pro získání diplomu celoživotního vzdělávání nejvíce <strong>75 kreditů</strong>.
                    </li>
                    <li>
                        Lékař může pro účely započítání kreditů v systému celoživotního vzdělávání absolvovat <strong>maximálně 2 e-learningové kurzy denně</strong>. Platnost nového diplomu celoživotního vzdělávání může být nastavena na následující den po posledním dnu platnosti předchozího diplomu (<strong>platnosti diplomů se nesmí překrývat</strong>).
                    </li>
                </ul>
                <p>Veškeré změny naleznete v novém znění Stavovského předpisu č. 16 <a href='https://soubory-vzdelavani-l.b-cdn.net/SP-16%20finalni-zneni.pdf' target='_blank'>ZDE</a></p>
                <p>V případě dotazů se obracejte na oddělení vzdělávání ČLK, e-mail: <a href="mailto:vzdelavani3@clkcr.cz">vzdelavani3@clkcr.cz</a>, tel.: +420 234 760 703.</p>
            `;
                }
                if (this.userRole === 'registrar') {
                    return `
                <p>Od 1. 1. 2025 dochází ke změně ve Stavovském předpisu č.16.</p>
                <div class="font-weight-bold mb-2" style='text-decoration: underline'>Nejdůležitější změny pro poskytovatele:</div>
                <ul class="mb-4">
                    <li>
                        povinnosti nahrávat prezenční listinu absolventů vč. data absolvování, <strong>do 10 dnů po ukončení</strong> vzdělávací akce do portálu vzdělávacích akcí, v případě <strong>e-learningu minimálně 1x měsíčně.</strong>
                    </li>
                    <li>
                        pro získání akreditace e-learningového kurzu bude zapotřebí <strong>délka 2 vyučovacích hodin (90 minut) a 15 otázek v rámci autodidaktického testu</strong>.
                    </li>
                    <li>
                        Se změnou Stavovského předpisu dochází i ke změně výše akreditačních poplatků, viz. <strong>Příloha č.1 §4</strong>
                    </li>
                </ul>
                <p>Veškeré změny naleznete v novém znění Stavovského předpisu č. 16 <a href='https://soubory-vzdelavani-l.b-cdn.net/SP-16%20finalni-zneni.pdf' target='_blank'>ZDE</a></p>
            `;
                } else {
                    return '';
                }
            }
        },

        mounted() {
            this.userRole = getUserRole();
            if (localStorage.getItem('showSPDialog')) {
                this.showSPDialog = false;
            } else {
                this.showSPDialog = true;
            }

            if (localStorage.getItem('showSPBanner')) {
                this.showSPBanner = false;
            } else {
                this.showSPBanner = true;
            }
        },

        methods: {
            handledClickDialog() {
                localStorage.setItem('showSPDialog', 'false');
                this.showSPDialog = false;
            },
            handleClickBanner() {
                localStorage.setItem('showSPBanner', 'false');
                this.showSPBanner = false;
            }
        }

    };
</script>

<style lang="scss" scoped>
.sp-16-banner {
    background-color: #f0f9fe;
    padding: 1rem;
    border: 1px solid red;
    border-radius: 1rem;
    margin-bottom: 2rem;
    font-size: 1rem;
}
</style>
